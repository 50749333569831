<template>
  <div class="form-container">
    <!-- Main Form -->
    <a-card :bordered="false" class="card-main-form" :bodyStyle="{paddingTop: 0}">
      <template #title>
        <!-- Back Button -->
    <div class="back-button" @click="goBack">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-return-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5"/>
</svg>
    </div>
        <div class="form-header">
            <img src="images/logos/logo-shopify.svg" alt="Shopify Icon" class="icon" />
          </div>
        <h6 style="text-align:center;"> New connection</h6>
      </template>

      <a-form id="main-form" :form="form" class="form-content" @submit="handleSubmit">
        <a-form-item class="mb-8">
          <label><b>Title</b></label>
          <a-input placeholder="Enter title" v-decorator="['title', { rules: [{ required: true, message: 'Please input the title!' }] }]" />
        </a-form-item>

        <a-form-item class="mb-8">
          <label><b>Interval</b></label>
          <a-input-group compact>
            <a-input-number
              v-decorator="['interval', { rules: [{ required: true, message: 'Please input the interval!' }] }]"
              min="1"
              placeholder="Enter interval"
              style="width: 60%"
            />
            <a-select
              v-decorator="['intervalUnit']"
              style="width: 40%"
              placeholder="Select unit"
            >
              <a-select-option value="minutes">Minutes</a-select-option>
              <a-select-option value="hours">Hours</a-select-option>
              <a-select-option value="days">Days</a-select-option>
            </a-select>
          </a-input-group>
        </a-form-item>

        <a-form-item class="mb-8">
          <label><b>Action</b></label>
          <a-input placeholder="Enter action" v-decorator="['action', { rules: [{ required: true, message: 'Please input the action!' }] }]" />
        </a-form-item>

        <a-form-item class="mb-8">
          <label><b>API key 1</b></label>
          <a-input placeholder="Enter API key 1" v-decorator="['apiKey1', { rules: [{ required: true, message: 'Please input the API key 1!' }] }]" />
        </a-form-item>

        <a-form-item class="mb-8">
          <label><b>API key 2</b></label>
          <a-input placeholder="Enter API key 2" v-decorator="['apiKey2', { rules: [{ required: true, message: 'Please input the API key 2!' }] }]" />
        </a-form-item>

        <a-form-item>
          <a-button type="primary" block html-type="submit">CREATE</a-button>
        </a-form-item>
        <a-form-item class="routing-icon">
          <span class="edit-icon" @click="toggleMappingForm">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-diagram-2-fill" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H11a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 5 7h2.5V6A1.5 1.5 0 0 1 6 4.5zm-3 8A1.5 1.5 0 0 1 4.5 10h1A1.5 1.5 0 0 1 7 11.5v1A1.5 1.5 0 0 1 5.5 14h-1A1.5 1.5 0 0 1 3 12.5zm6 0a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1A1.5 1.5 0 0 1 9 12.5z"/>
            </svg>
          </span>
        </a-form-item>
      </a-form>
    </a-card>

    <!-- Mapping Form Sidebar -->
    <a-card v-if="showMappingForm" class="card-mapping-form">
      <template #title>
        <h5 class="font-semibold">Mapping</h5>
      </template>

      <div class="mapping-fields">
        <div class="mapping-row">
          <a-form-item class="mapping-item">
            <label>SKU</label>
            <a-input placeholder="Enter SKU" />
          </a-form-item>
          <a-form-item class="mapping-item">
            <label>ORDER</label>
            <a-input placeholder="Enter order ID" />
          </a-form-item>
        </div>
        <div class="mapping-row">
          <a-form-item class="mapping-item">
            <label>DATE</label>
            <a-date-picker placeholder="Enter date" />
          </a-form-item>
          <a-form-item class="mapping-item">
            <label>CUSTOMERID</label>
            <a-input placeholder="Enter customer ID" />
          </a-form-item>
        </div>
      </div>

      <a-button type="primary" block @click="confirmMapping">CONFIRM MAPPING</a-button>
    </a-card>
  </div>
</template>


<script>
export default {
  data() {
    return {
      form: this.$form.createForm(this),
      showMappingForm: false,
      mappingFields: ['SKU', 'ORDER', 'DATE', 'CUSTOMERID'],
    };
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          alert('Form submitted', values);
        }
      });
    },
    toggleMappingForm() {
      this.showMappingForm = !this.showMappingForm;
    },
    confirmMapping() {
      this.showMappingForm = false;
    },
    goBack() {
      // Add navigation logic here, e.g., this.$router.go(-1) for Vue Router
      alert("Backbutton clicked");
    },
  },
};
</script>

<style lang="scss">
.form-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  padding: 20px;
  position: relative;
}

.back-button {
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #1890ff;
}

.card-main-form {
  width: 400px;
  position: relative;
}

.form-header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.icon {
  width: 50px;
  height: 50px;
}

.routing-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #1890ff;
}

.form-content {
  padding: 10px 20px;
}

.card-mapping-form {
  width: 500px; /* Smaller width to make it square-like */
  padding: 30px; /* Increase padding for more space between fields */
  display: flex;
  flex-direction: column;
  gap: 15px; /* Increased gap for bigger spacing */
}

.mapping-fields {
  display: flex;
  flex-direction: column;
  gap: 15px; /* Increased gap for bigger spacing */
}

.mapping-row {
  display: flex;
  gap: 10px; /* Space between inputs in each row */
}

.mapping-item label {
  font-weight: bold;
  font-size: 15px;
}

.mb-8 {
  margin-bottom: 8px; /* Reduced margin for the first form */
}

.mb-10 {
  margin-bottom: 15px; /* Increased margin for the second form */
}
</style>

